




















































import { Vue, Component, Prop } from 'vue-property-decorator'
import { ValidationProvider } from 'vee-validate'

@Component({
  components: {
    ValidationProvider,
  },
})
export default class PasswordInput extends Vue {
  @Prop({
    default: '',
    type: String,
  })
  value!: string

  @Prop({
    type: Boolean,
  })
  noConfirm!: boolean

  @Prop({
    type: Boolean,
  })
  column!: boolean

  @Prop({
    type: Boolean,
  })
  noValidation!: boolean

  get rules() {
    return this.noValidation
      ? ''
      : 'required|min:8|has_uppercase|has_lowercase|has_digit|has_special'
  }

  showPassword = false
  confirmPassword = ''
}
