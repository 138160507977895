















import { Vue, Component, Prop } from 'vue-property-decorator'
import { ValidationProvider } from 'vee-validate'

@Component({
  components: {
    ValidationProvider,
  },
})
export default class ConfirmationCheckbox extends Vue {
  @Prop({
    default: 'You must accept this item',
    type: String,
  })
  errorMessage!: string

  @Prop({
    default: false,
    type: Boolean,
  })
  value!: boolean

  emit() {
    this.$emit('input', this.value)
  }
}
