




















import ConfirmationCheckbox from './ConfirmationCheckbox.vue'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { MARKETING_URL } from '@/modules/config'

@Component({
  components: {
    ConfirmationCheckbox,
  },
})
export default class AcceptTermsAndConditions extends Vue {
  @Prop({
    default: false,
    type: Boolean,
  })
  isSeller!: boolean

  MARKETING_URL = MARKETING_URL
}
