































































































































































































import ValidationMessages from '@/components/ValidationMessages.vue'
import AcceptTermsAndConditions from '@/components/inputs/AcceptTermsAndConditions.vue'
import AddressForm from '@/components/forms/AddressForm.vue'
import PasswordInput from '@/components/inputs/PasswordInput.vue'
import { Vue, Component } from 'vue-property-decorator'

import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { SellerDTO } from '@/api-client'
import { removeSpaces } from '@/modules/stringUtils'
import ApiError, { ApiErrorCode } from '@/types/ApiError'
import { MARKETING_URL, PRODUCT_NAME } from '@/modules/config'

@Component({
  head: {
    title() {
      return {
        inner: 'Sign up',
      }
    },
  },
  components: {
    ValidationObserver,
    ValidationProvider,
    PasswordInput,
    AddressForm,
    AcceptTermsAndConditions,
    ValidationMessages,
  },
})
export default class SignUp extends Vue {
  PRODUCT_NAME = PRODUCT_NAME
  MARKETING_URL = MARKETING_URL

  loading = false
  showPassword = false
  showConfirmPassword = false
  signUpErrors: string[] = []

  sellerDetails: SellerDTO = {
    emailAddress: '',
    givenName: '',
    familyName: '',
    phoneNumber: '',
    realEstateLicenceNumber: '',
    companyName: '',
    companyAbn: '',
    companyAddress: {
      line1: '',
      suburb: '',
      state: '',
      postalCode: '',
      country: 'Australia',
    },
    marketingOptIn: false,
  }

  password = ''

  async register() {
    this.loading = true
    this.signUpErrors = []

    await this.$api.seller
      .v1SellersRegisterPost({
        seller: {
          ...this.sellerDetails,
          companyAbn: removeSpaces(this.sellerDetails.companyAbn),
        },
        password: this.password,
      })
      .then(() => {
        this.$router.push({
          path: '/unconfirmed',
          query: {
            email: this.sellerDetails.emailAddress,
          },
        })
      })
      .catch((err: ApiError) => {
        if (err.code === ApiErrorCode.COGNITO_NOT_ALLOWED) {
          this.signUpErrors = ['This username is taken']
        } else {
          this.signUpErrors = ['An error occurred.']
        }
      })

    this.loading = false
  }
}
