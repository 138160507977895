















import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class ValidationMessages extends Vue {
  @Prop({
    required: true,
    type: Array,
  })
  value!: string[]

  @Prop({
    default: 'error',
    type: String,
  })
  color!: string
}
