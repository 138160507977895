export enum ApiErrorCode {
  RELEASE_NOT_FOUND = 'RELEASE_NOT_FOUND',
  RELEASE_NOT_EDITABLE = 'RELEASE_NOT_EDITABLE', // Release status isn't draft
  RELEASE_INCOMPLETE = 'RELEASE_INCOMPLETE', // One (or more) of Details, SigningDetails, Lots, SigningTimes is empty OR total lots isn't greater than total signing times
  RELEASE_NOT_PUBLISHED = 'RELEASE_NOT_PUBLISHED', // Release status isn't published
  RELEASE_COMPLETE = 'RELEASE_COMPLETE',
  RELEASE_CANCELLED = 'RELEASE_CANCELLED',
  APPLICATION_NOT_FOUND = 'APPLICATION_NOT_FOUND',
  APPLICATION_NOT_ALLOWED = 'APPLICATION_NOT_ALLOWED', // Duplicate submissions
  SELLER_NOT_REGISTERED = 'SELLER_NOT_REGISTERED',
  SELLER_DENIED = 'SELLER_DENIED',
  COGNITO_NOT_ALLOWED = 'COGNITO_NOT_ALLOWED',
  COGNITO_ERROR = 'COGNITO_ERROR',
  STRIPE_ERROR = 'STRIPE_ERROR',
  SIGNING_TIME_NOT_FOUND = 'SIGNING_TIME_NOT_FOUND',
}

interface ApiError {
  code: ApiErrorCode
  message?: string
}

export default ApiError
