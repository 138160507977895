var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mx-auto",style:(("max-width : " + (_vm.$vuetify.breakpoint.thresholds.sm) + "px"))},[_c('div',{staticClass:"text-h2 mb-12"},[_vm._v("Create seller account")]),_c('div',{staticClass:"text-h4 mb-6"},[_vm._v("Credentials")]),_c('p',{staticClass:"body-1"},[_vm._v(" You will need these to sign in to "+_vm._s(_vm.PRODUCT_NAME)+" in the future. ")]),_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var validate = ref.validate;
var errors = ref.errors;
return [_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pr-0 pr-sm-3",attrs:{"cols":"12","sm":"6"}},[_c('ValidationProvider',{attrs:{"mode":"eager","name":"Email address","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","error-messages":errors,"label":"Email address"},model:{value:(_vm.sellerDetails.emailAddress),callback:function ($$v) {_vm.$set(_vm.sellerDetails, "emailAddress", $$v)},expression:"sellerDetails.emailAddress"}})]}}],null,true)})],1)],1),_c('PasswordInput',{model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('div',{staticClass:"text-h4 mt-10 mb-6"},[_vm._v(" Contact details "),_c('v-tooltip',{attrs:{"top":"","max-width":"260"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-1 mb-1",attrs:{"color":"info","dark":""}},'v-icon',attrs,false),on),[_vm._v(" $info ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.PRODUCT_NAME)+" is designed for use by authorised sellers of real estate (please refer to the seller T&Cs) and we ask for your real estate license to confirm that you are authorised to sell real estate.")])])],1),_c('p',{staticClass:"body-1"},[_vm._v(" The following information is required for a seller to create an account. ")]),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pr-0 pr-sm-3",attrs:{"cols":"12","sm":"6"}},[_c('ValidationProvider',{attrs:{"mode":"eager","name":"Given name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","error-messages":errors,"label":"Given name"},model:{value:(_vm.sellerDetails.givenName),callback:function ($$v) {_vm.$set(_vm.sellerDetails, "givenName", $$v)},expression:"sellerDetails.givenName"}})]}}],null,true)})],1),_c('v-col',{staticClass:"pl-0 pl-sm-3",attrs:{"cols":"12","sm":"6"}},[_c('ValidationProvider',{attrs:{"mode":"eager","name":"Family name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","error-messages":errors,"label":"Family name"},model:{value:(_vm.sellerDetails.familyName),callback:function ($$v) {_vm.$set(_vm.sellerDetails, "familyName", $$v)},expression:"sellerDetails.familyName"}})]}}],null,true)})],1),_c('v-col',{staticClass:"pr-0 pr-sm-3",attrs:{"cols":"12","sm":"6"}},[_c('ValidationProvider',{attrs:{"mode":"eager","name":"Phone number","rules":"required|phone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","error-messages":errors,"label":"Phone number"},model:{value:(_vm.sellerDetails.phoneNumber),callback:function ($$v) {_vm.$set(_vm.sellerDetails, "phoneNumber", $$v)},expression:"sellerDetails.phoneNumber"}})]}}],null,true)})],1),_c('v-col',{staticClass:"pl-0 pl-sm-3",attrs:{"cols":"12","sm":"6"}},[_c('ValidationProvider',{attrs:{"mode":"eager","name":"Real estate licence number","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","error-messages":errors,"label":"Real estate licence number"},model:{value:(_vm.sellerDetails.realEstateLicenceNumber),callback:function ($$v) {_vm.$set(_vm.sellerDetails, "realEstateLicenceNumber", $$v)},expression:"sellerDetails.realEstateLicenceNumber"}})]}}],null,true)})],1)],1),_c('div',{staticClass:"text-h4 mt-10 mb-6"},[_vm._v("Business details")]),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pr-0 pr-sm-3",attrs:{"cols":"12","sm":"6"}},[_c('ValidationProvider',{attrs:{"mode":"eager","name":"Business name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","error-messages":errors,"label":"Business name"},model:{value:(_vm.sellerDetails.companyName),callback:function ($$v) {_vm.$set(_vm.sellerDetails, "companyName", $$v)},expression:"sellerDetails.companyName"}})]}}],null,true)})],1),_c('v-col',{staticClass:"pl-0 pl-sm-3",attrs:{"cols":"12","sm":"6"}},[_c('ValidationProvider',{attrs:{"mode":"eager","name":"Business ABN or ACN","rules":"required|check_abn_or_acn"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","error-messages":errors,"label":"Business ABN or ACN"},model:{value:(_vm.sellerDetails.companyAbn),callback:function ($$v) {_vm.$set(_vm.sellerDetails, "companyAbn", $$v)},expression:"sellerDetails.companyAbn"}})]}}],null,true)})],1)],1),_c('AddressForm',{model:{value:(_vm.sellerDetails.companyAddress),callback:function ($$v) {_vm.$set(_vm.sellerDetails, "companyAddress", $$v)},expression:"sellerDetails.companyAddress"}}),_c('AcceptTermsAndConditions',{attrs:{"is-seller":true}}),_c('v-checkbox',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"pl-2"},[_vm._v(" I would like to stay up to date with the latest marketing material from "+_vm._s(_vm.PRODUCT_NAME)+". ")])]},proxy:true}],null,true),model:{value:(_vm.sellerDetails.marketingOptIn),callback:function ($$v) {_vm.$set(_vm.sellerDetails, "marketingOptIn", $$v)},expression:"sellerDetails.marketingOptIn"}}),_c('v-divider',{staticClass:"my-12"}),_c('div',{staticClass:"d-sm-flex d-block"},[_c('p',[_vm._v(" Already have an account? "),_c('router-link',{staticClass:"d-inline accent-pine--text",attrs:{"to":"/sign-in"}},[_vm._v("Sign in")])],1),_c('br',{staticClass:"d-sm-none d-block"}),_c('v-spacer'),_c('v-btn',{staticClass:"mx-0 mx-sm-6 mb-6 mb-sm-0",attrs:{"block":_vm.$vuetify.breakpoint.xs,"outlined":"","href":_vm.MARKETING_URL}},[_vm._v("Cancel")]),_c('ValidationMessages',{attrs:{"value":_vm.signUpErrors}},[_c('v-btn',{attrs:{"block":_vm.$vuetify.breakpoint.xs,"loading":_vm.loading,"disabled":errors.length,"color":"primary"},on:{"click":function () { return (passed ? _vm.register() : validate()); }}},[_vm._v("Create account")])],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }