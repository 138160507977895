









































































import { Vue, Component, Prop } from 'vue-property-decorator'
import { states } from '@/modules/config'
import Address from '@/types/Address'
import { ValidationProvider } from 'vee-validate'
import { Validatable } from '@/types/validation'

@Component({
  components: {
    ValidationProvider,
  },
})
export default class AddressForm extends Vue {
  @Prop({
    default: {
      line1: '',
      suburb: '',
      state: '',
      postalCode: '',
    },
    type: Object,
  })
  value!: Address

  internalValue: Address = {
    line1: '',
    suburb: '',
    state: '',
    postalCode: '',
  }

  get isEmptyValue() {
    const { line1, suburb, state, postalCode } = this.internalValue
    // its not empty if any of these have a "truthy" value
    return ![line1, suburb, state, postalCode].some(v => v)
  }

  mounted() {
    this.internalValue = this.value

    if (!this.isEmptyValue) {
      this.$nextTick(() => {
        // This is required so that validation is triggered for the fields that we automatically populated.
        Object.values(
          (this.$refs as unknown) as Record<string, Validatable>,
        ).map(value => {
          if (value.validate) {
            value.validate()
          }
        })
      })
    }
  }

  get states() {
    return states.map(s => s.postal)
  }

  emit() {
    this.$emit('input', this.internalValue)
  }
}
